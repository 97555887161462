import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { SearchParams } from 'src/app/services/base/api.base.service';
import { FamiliaApiService } from 'src/app/services/endpoints/familia.service';
import { UsuarioApiService } from 'src/app/services/endpoints/usuario.service';
import { FamiliaModel } from 'src/app/services/models/familia.model';
import { UsuarioModel } from 'src/app/services/models/usuario.model';

@Component({
  selector: 'app-registro-familia',
  templateUrl: './registro-familia.component.html',
  styleUrls: ['./registro-familia.component.scss'],
})
export class RegistroFamiliaComponent implements OnInit {

  constructor(
    public modalController:ModalController,
     private familiaApiService: FamiliaApiService,
     private ngFireAuth: AngularFireAuth,
     private usuarioApiService:UsuarioApiService) { }

  registroNuevaFamilia= new FormBuilder().group({
    apellidos:['',Validators.required],   
    movil:['',[Validators.required, Validators.pattern("[0-9]{9}")]],
    email:['',[Validators.required,Validators.email]], 
    nif:['',[Validators.required, Validators.pattern("[0-9]{8}[A-Za-z]{1}")]],
    miembros:['',Validators.required],   
   
  })

  ngOnInit() {}

  buttonBack(reload = false){
    this.modalController.dismiss({
      'dismissed': true,
      'reload':reload
    });
  }

 

  submitNuevaFamilia(){
     // * PARAMETROS PARA LA CREACION DE UN USUARIO
     const email = this.registroNuevaFamilia.value.email;
     // * CREAMOS USUARIO
     const usuario = new UsuarioModel();
     usuario.correo = email;
     usuario.rol = "Familia";
     usuario.contraseña = this.registroNuevaFamilia.value.nif;
 
     // * PARAMETROS PARA LA CREACION DE UNA FAMILIA
     const nombre = this.registroNuevaFamilia.value.apellidos;
     const movil = this.registroNuevaFamilia.value.movil;
     const nif = this.registroNuevaFamilia.value.nif;
     
 
    this.ngFireAuth.createUserWithEmailAndPassword(usuario.correo, usuario.contraseña).then((res)=>{
      this.usuarioApiService.signUp(usuario).then(async(response:any)=>{
        const params: SearchParams = {
          domain: [
            {
              field: "correo",
              operation: "$eq",
              values: usuario.correo,
            },
          ],
        };
    this.usuarioApiService.getUsuario(params).then((responseUsuario:any)=>{
      console.log('usuario', responseUsuario.data[0]);
      
      const familia = new FamiliaModel();
      familia.usuario= responseUsuario.data[0]._id;
      familia.nombre = nombre;
      familia.movil= movil;
      familia.nif= nif;

      
      this.familiaApiService.createFamilia(familia).then((responseFamilia:any)=>{
        console.log("la familia", responseFamilia.data)
        console.log("familia creada");
        
        console.log("Familia creada")
        this.buttonBack(true);
      });
      console.log("respuesta", response);
    });

  })
       
  }).catch(async(e) => {
    console.log("ERROR EN LA AUTENTIFICACION")
    });

  }


}