import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService, SearchParams } from '../base/api.base.service';
import { StorageService } from '../base/storage.service';
import { BonoecoModel} from '../models/bonoeco.model';

@Injectable()
export class BonoecoApiService extends ApiService<BonoecoModel>{
    readonly model: string = 'bonoeco';

    constructor(http: HttpClient, sessionS: StorageService){super(http, sessionS);}

    // Función que trae los bonoecos acorde a los parametros de búsqueda
    async getBonoeco(params?: SearchParams){
        return await this.get(this.model, params);
    }

    // Función que trae un bonoeco por un id
    async getBonoecoById(id: string){
        return await this.getbyId(this.model, id);
    }

    //Función que cuenta el numero de bonoecos en base a parámetros
    async getNumeroBonoecos(params?: SearchParams){
        return await this.getCount(this.model, params);
    }

    // Función que crea un bonoeco
    async createBonoeco(newEvent: BonoecoModel){
        return await this.postCreate(this.model, newEvent);
    }

    // Función que modifica un bonoeco
    // Caso de uso, se quiere modificar el nombre de un bonoeco
    async modifyBonoeco(id: string, newEvent: BonoecoModel){
        return await this.put(this.model, id, newEvent);
    }

    // Función que borra un bonoeco
    async deleteBonoeco(id: string){
        return await this.delete(this.model, id);
    }

}
