import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { ApiService } from '../services/base/api.base.service';
import { StorageService } from '../services/base/storage.service';
import { UsuarioModel } from '../services/models/usuario.model';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {
  constructor(
    private router: Router,
    private storageS: StorageService,
    private apiBase: ApiService<UsuarioModel>
  ) { }
  async canLoad(): Promise<boolean> {
    const hasToken = await this.storageS.getToken();
    let user: UsuarioModel;
    try{user = await this.apiBase.getSession();}catch{}
    finally{
      if (hasToken && user._id) {
        return true;
      } else {
        this.router.navigate(['']);
        return false;
      }
    }
  }
}

