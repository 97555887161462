/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiServiceImp } from './api.interface';
import { environment } from '../../../environments/environment';
import { StorageService } from './storage.service';

@Injectable()
export class ApiService<T> implements ApiServiceImp {

    requestOptions = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Access-Control-Allow-Headers': '*',
    };
    private url: string = environment.API_URL;

    constructor(
        public http: HttpClient,
        public storageS: StorageService
        ) { }

    // url/api+/model/search + params?
    get<T>(model: string, params?: SearchParams) {
        if(!environment.production){this.message('GET', model);}
        const options = this.createOptions();
        let url = this.url + '/' + model + '/search';
        url = params ? this.createUrlWithParams(url, params) : url;
        return this.http.get<T>(url, options).toPromise();
    }

    // url/api+/model/id
    getbyId<T>(model: string, id: string) {
        if(!environment.production){this.message('GET', model);}
        const options = this.createOptions();
        const url = this.url + '/' + model + '/' + id;
        return this.http.get<T>(url, options).toPromise();
    }

    /* Devuelve el schema de la colleccion
    // url/api+/model/schema
    getSchema<T>(model: string) {
        environment.production ? 0 : this.message('GET', model);
        let options = this.createOptions();
        let url = this.url + '/' + model + '/schema';
        return this.http.get<T>(url, options).toPromise();
    }
    */

    // Una peticion que devuelva el conteo en base a parametros
    // url/api+/model/count + params?
    getCount<T>(model: string, params?: SearchParams) {
        if(!environment.production){this.message('GET', model);}
        const options = this.createOptions();
        let url = this.url + '/' + model + '/count';
        url = params ? this.createUrlWithParams(url, params) : url;
        return this.http.get<T>(url, options).toPromise();
    }


    // POST url/api+/model + body
    post<T>(model: string, body: object, contentType = null) {
        if(!environment.production){this.message('POST', model);}
        const options = this.createOptions(contentType);
        return this.http.post<T>(this.url + '/' + model, body, options).toPromise();
    }

    // POST url/api+/model/create + body
    postCreate<T>(model: string, body: object, contentType = null) {
        if(!environment.production){this.message('POST', model);}
        const options = this.createOptions(contentType);
        return this.http.post<T>(this.url + '/' + model + '/create', body, options).toPromise();
    }

    // PUT url/api+/model/id + body
    put<T>(model: string, id: string, body: object, contentType = null) {
        if(!environment.production){this.message('PUT', model);}
        const options = this.createOptions(contentType);
        const url = this.url + '/' + model + '/' + id;
        return this.http.put<T>(url, body, options).toPromise();
    }

    //DELETE url/api+/model/id
    delete<T>(model: string, id: string) {
        if(!environment.production){this.message('DELETE', model);}
        const options = this.createOptions();
        return this.http
            .delete<T>(this.url + '/' + model + '/' + id, options)
            .toPromise();
    }

    login(correo: string,contraseña: string){
        const url = this.url + '/auth/login';
        const body: object = {
            correo,
            contraseña
        };
        const headers = new HttpHeaders(this.requestOptions);
        return this.http.post(url, body, {headers}).toPromise();
    }

    loginFirebase(correo: string){
        const url = this.url + '/auth/loginFirebase';
        const body: object = {
            correo
        };
        const headers = new HttpHeaders(this.requestOptions);
        return this.http.post(url, body, {headers}).toPromise();
    }

    signUp(body: object, contentType = null){
        const url = this.url + '/auth/signup';
        const headers = new HttpHeaders(this.requestOptions);
        return this.http.post(url, body, {headers}).toPromise();
    }

    getSession(){
        if(!environment.production){this.message('getSession','Sesion');}
        const options = this.createOptions();
        return this.http
            .get<T>(this.url + '/auth/getSession',options)
            .toPromise();
    }

    // Función que solo ejecuta en entorno de test para ver las llamadas
    private message(method: string, model: string) {
        console.log('Request', method, model);
    }

    // Función que crea las cabeceras de la petición, en el momento en que haya
    // API-KEY será la que se encargue de ello
    private createOptions(contentType: string = '') {
        let headers = new HttpHeaders(this.requestOptions);
        //headers = headers.append('api-key', environment.apikey);
        const token: string = this.storageS.getToken();
        if(token){ headers = headers.append('Authorization', `Bearer ${token}`);}
        if (contentType) {headers = headers.append('content-type', contentType);}
        return {
            headers
        };
    }

    // Función que maneja los parametros de busqueda y los agrega a la url
    private createUrlWithParams(url: string, params: SearchParams) {
        let urlParams = '?';
        let modify = false;
        if (params.domain) {
            const prueba: any = params.domain;
            const aux = logicOperators.indexOf(prueba.operation)>=0 ? this.processLogicDomain(prueba) : this.processDomain(params.domain);
            urlParams = urlParams + (modify ? '&domain={' : 'domain={') + aux + '}';
            modify = true;
        }
        if (params.order) {
            let aux = '';
            params.order.forEach((order: Orders) => {
                aux = (aux ? aux + ',' : aux) + '"' + order.clave + '"' + ':' + order.valor;
            });
            urlParams = urlParams + (modify ? '&order={' : 'order={') + aux + '}';
            modify = true;
        }
        if (params.limit) {
            urlParams = urlParams + (modify ? '&limit=' + params.limit : 'limit=' + params.limit);
            modify = true;
        }
        return url + urlParams;
    }

    private processLogicDomain(domain: LogicDomain){
        let aux = '';
        aux = aux + '"' + domain.operation + '"' + ':';
        let auxDomains='[';
        domain.domains.forEach((domainElem: Domain)=> {
           auxDomains = auxDomains + '{';
           auxDomains = auxDomains + '"' + domainElem.field + '"' + ':' + '{' + '"' + domainElem.operation + '"' + ':';
           if (Array.isArray(domainElem.values)) {
               // Si es un array construye el array
               auxDomains = auxDomains + '[';
               domainElem.values.forEach(element => {
                const aux_2 = element!==null ? '"' + element + '"' : 'null';
                auxDomains = auxDomains + aux_2 + ',';
               });
               auxDomains = auxDomains.slice(0, aux.length - 1) + ']' + '}';
           } else {
               // Sino, añade el valor
               const aux_2 = domainElem.values!==null ? '"' + domainElem.values + '"' : 'null';
               auxDomains = auxDomains + aux_2 + '}';
           }
           auxDomains = auxDomains + '},';

        });
        auxDomains = auxDomains.slice(0, auxDomains.length - 1);
        auxDomains = auxDomains + ']';
        aux = aux + auxDomains;
        return aux;
    }

    private processDomain(domain) {
        let aux = '';
        if (Array.isArray(domain)) {
            // Si el domain es un array lo recorre e itera
            domain.forEach(domainArray => {
                //Añade el campo field y operacion
                aux = aux + '"' + domainArray.field + '"' + ':' + '{' + '"' + domainArray.operation + '"' + ':';
                if (Array.isArray(domainArray.values)) {
                    // Si es un array construye el array
                    aux = aux + '[';
                    domainArray.values.forEach(element => {
                        aux = aux + '"' + element + '"' + ',';
                    });
                    aux = aux.slice(0, aux.length - 1) + ']' + '}';
                } else {
                    // Sino, añade el valor
                    aux = aux + '"' + domainArray.values + '"' + '}';
                }
                aux = aux + ',';
            });
            aux = aux.slice(0, aux.length - 1);
        } else {
            // Si el domain no es un array construye la url
            aux = '"' + domain.field + '"' + ':' + '{' + '"' + domain.operation + '"' + ':';
            if (Array.isArray(domain.values)) {
                aux = aux + '[';
                domain.values.forEach(element => {
                    const aux_2 = element!==null ? '"' + element + '"' : 'null';
                    aux = aux + aux_2 + ',';
                });
                aux = aux.slice(0, aux.length - 1) + ']' + '}';
            } else {
                const aux_2 = domain.values!==null ? '"' + domain.values + '"' : 'null';
                aux = aux + aux_2 + '}';
            }
        }
        return aux;
    }

}

// Hay que mejorar los searchparams y adecuarlos a Mongo (opcional)
export type SearchParams = {
    domain?: LogicDomain | Domain | Array<Domain>;
    limit?: number;
    offset?: number;
    order?: Array<Orders>;
    fields?: Array<string>;
};
export type Domain = {
    field: string;
    operation: string;
    values: Array<any> | number | string | Date | boolean;
};
export type LogicDomain = {
    operation: string;
    domains: Array<Domain>;
};
export const logicOperators = [
    '$and',
    '$not',
    '$nor',
    '$or'
];
export type Orders = {
    clave: string;
    valor: 1 | -1; //en un futuro si hay más ordenaciones van aquí
};
 /*
    Las operaciones comparativas que permite mongo son las siguientes:
        - $eq   => Igualdad
        - $ne   => Desigual
        - $gt   => Mayor
        - $lt   => Menor
        - $gte  => Mayor o igual
        - $lte  => Menor o igual
        - $in   => Valor en la lista
        - $nin  => Valor no en la lista
    Las operaciones logicas que permite son:
        - $and
        - $not
        - $nor
        - $or

    Ejemplos:
    let order: Array<Orders> = [
    {
        clave: "empresa",
        valor: 1 //asc
    }, {
        clave: "nombre",
        valor: -1 //desc
    },
    ];
    let params: SearchParams = {
        domain: [{
            field: "apellido",
            operation: "$nin",
            values: ["Perez", "Garcia"]
        }],
        limit: 1,
        order: order
    }
    this.userS.getUsuario(params).then((e: any) => {
        this.usuario = e[0];
    });
    let params: SearchParams = {
        domain: {
            field: "nombre",
            operation: "$in",
            values: ["Juan Carlos", "Margarita"]
        }
    };
    this.userS.getUsuario(params).then((e: any) => {
        this.usuario = e[0];
    });*/


