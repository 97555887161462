import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ComercioApiService } from 'src/app/services/endpoints/comercio.service';
import { CompraApiService } from 'src/app/services/endpoints/compra.service';

@Component({
  selector: 'app-tickets-familia',
  templateUrl: './tickets-familia.component.html',
  styleUrls: ['./tickets-familia.component.scss'],
})
export class TicketsFamiliaComponent implements OnInit {
  // Data passed in by componentProps
  @Input() compraId: string;
  constructor(
    public modalController:ModalController,
    private compraApiService: CompraApiService,
    private comercioApiService:ComercioApiService
    ) { }

  compra;
  comercio;

  ngOnInit() {
    console.log(this.compraId);
    this.getCompraById();
  }

  buttonBack(){
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  getCompraById(){
    this.compraApiService.getCompraById(this.compraId).then((response:any)=>{
      this.compra = response.data;
      this.getComercioById(this.compra.comercio)
    })
  }

  getComercioById(comercioId){
    this.comercioApiService.getComercioById(comercioId).then((response:any)=>{
      this.comercio = response.data;
    })
  }
}
