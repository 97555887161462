export class UsuarioModel {
    getSession() {
      throw new Error('Method not implemented.');
    }
    _id?: string;
    correo: string;
    rol: string;
    contraseña: string;
    // Las referencias son el id del modelo relacionado
}
