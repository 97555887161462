import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService, SearchParams } from '../base/api.base.service';
import { StorageService } from '../base/storage.service';
import { AdministradorModel } from '../models/administrador.model';
@Injectable()
export class AdministradorApiService extends ApiService<AdministradorModel>{
    readonly model: string = 'administrador';

    constructor(http: HttpClient, sessionS: StorageService){super(http, sessionS);}

    // Función que trae los comercios acorde a los parametros de búsqueda
    async getAdministrador(params?: SearchParams){
        return await this.get(this.model, params);
    }

    // Función que trae un comercio por un id
    async getAdministradorById(id: string){
        return await this.getbyId(this.model, id);
    }

    //Función que cuenta el numero de comercios en base a parámetros
    async getNumeroAdministradores(params?: SearchParams){
        return await this.getCount(this.model, params);
    }

    // Función que crea un comercio
    async createAdministrador(newEvent: AdministradorModel){
        return await this.postCreate(this.model, newEvent);
    }

    // Función que modifica un comercio
    // Caso de uso, se quiere modificar el nombre de un comercio
    async modifyAdministrador(id: string, newEvent: AdministradorModel){
        return await this.put(this.model, id, newEvent);
    }

    // Función que borra un comercio
    async deleteAdministrador(id: string){
        return await this.delete(this.model, id);
    }
}
