import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { ServicesModule } from "./services/services.module";
import { AngularFireModule } from '@angular/fire/compat/';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { environment } from "src/environments/environment";
import { DetallesUsuarioComponent } from "./components/detalles-usuario/detalles-usuario.component";
import { RegistroFamiliaComponent } from "./components/registro-familia/registro-familia.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RegistroComercioComponent } from "./components/registro-comercio/registro-comercio.component";
import { TicketsFamiliaComponent } from "./components/tickets-familia/tickets-familia.component";
import { TicketsComercioComponent } from "./components/tickets-comercio/tickets-comercio.component";
import { EditarFamiliaComponent } from "./components/editar-familia/editar-familia.component";
import { EditarComercioComponent } from "./components/editar-comercio/editar-comercio.component";
import { RegistroBonoComponent } from "./components/registro-bono/registro-bono.component";
import { InformacionBonoComponent } from "./components/informacion-bono/informacion-bono.component";


@NgModule({
  declarations: [
    AppComponent,
    DetallesUsuarioComponent,
    RegistroFamiliaComponent,
    RegistroComercioComponent,
    RegistroBonoComponent,
    TicketsFamiliaComponent,
    TicketsComercioComponent,
    InformacionBonoComponent,
    EditarFamiliaComponent,
    EditarComercioComponent,
    
  ],
    
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ServicesModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFireStorageModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
