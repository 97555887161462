import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { SearchParams } from 'src/app/services/base/api.base.service';
import { BonoecoApiService } from 'src/app/services/endpoints/bonoeco.service';
import { FamiliaApiService } from 'src/app/services/endpoints/familia.service';
import { BonoecoModel } from 'src/app/services/models/bonoeco.model';
import { FamiliaModel } from 'src/app/services/models/familia.model';

@Component({
  selector: 'app-registro-bono',
  templateUrl: './registro-bono.component.html',
  styleUrls: ['./registro-bono.component.scss'],
})
export class RegistroBonoComponent implements OnInit {

  familia;
  familiaBono;
  registroNuevoBono: FormGroup;

  constructor(
    private modalController: ModalController,
    private bonoApiService: BonoecoApiService,
    private familiaApiService: FamiliaApiService,
    private alertController: AlertController
  ) { }


  ngOnInit() {
    this.registroNuevoBono = new FormGroup({
      nombreFamilia: new FormControl(''),
      nif: new FormControl('', [Validators.required, Validators.pattern("[0-9]{8}[A-Za-z]{1}")]),
      cantidad: new FormControl('', Validators.required),
      porcentaje: new FormControl('', Validators.required),
    });
  }

  buttonBack(reload = false) {
    this.modalController.dismiss({
      'dismissed': true,
      'reload': reload
    });
  }

  submitNuevoBono() {

    //* PARAMETROS CREACION BONO

    const nif = this.registroNuevoBono.value.nif;
    const cantidad = this.registroNuevoBono.value.cantidad;
    const porcentaje = this.registroNuevoBono.value.porcentaje;

    //*FILTRO FAMILIA POR NIF

    const params: SearchParams = {
      domain: [
        {
          field: "nif",
          operation: "$eq",
          values: nif,
        },
      ],
    };

    this.familiaApiService.getFamilia(params).then(async (response: any) => {
      this.familia = response.data[0];

      if (!this.familia) {
        //* ALERTA FAMILIA NO EXISTE
        const alert = await this.alertController.create({
          cssClass: 'my-custom-class',
          header: 'Atención',
          message: 'El NIF introducido no corresponde a ninguna familia',
          buttons: ['Aceptar']
        });
        await alert.present();
      } else {

        if (this.familia.bonoEco) {
          //*ALERTA POR BONO ACTIVO EXISTENTE
          const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: 'Atención',
            message: 'Esta familia ya tiene un bonoEco activo',
            buttons: ['Aceptar']
          });

          await alert.present();

        } else {
          //*NUEVO BONO
          const bono = new BonoecoModel();
          bono.cantidad = cantidad;
          bono.porcentaje = porcentaje;
          bono.nombreFamilia = this.familia.nombre;
          bono.familia = this.familia._id;
          bono.estado = "Activo"

          //*CREAMOS BONO

          this.bonoApiService.createBonoeco(bono).then((responseBono: any) => {
            console.log("bono creado", responseBono.data);

            //*ACTUALIZAMOS FAMILIA
            const familiaId = this.familia._id;
            const newFamilia = new FamiliaModel;
            //* PARAMETROS A MODIFICAR (solo bonoeco)     
            newFamilia.bonoEco = responseBono.data._id;
            this.familiaApiService.modifyFamilia(familiaId, newFamilia);

            //*CERRAMOS MODAL
            this.buttonBack(true);
          });
        }
      }
    });
  }
}
