import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CompraApiService } from 'src/app/services/endpoints/compra.service';
import { FamiliaApiService } from 'src/app/services/endpoints/familia.service';

@Component({
  selector: 'app-tickets-comercio',
  templateUrl: './tickets-comercio.component.html',
  styleUrls: ['./tickets-comercio.component.scss'],
})
export class TicketsComercioComponent implements OnInit {
  @Input() compraId: string;

  compra;
  familia;

  constructor(
    public modalController:ModalController,
    private compraApiService: CompraApiService,
    private familiaApiService: FamiliaApiService,
    ) { }

  ngOnInit() {
    console.log(this.compraId);
    this.getCompraById();
  }
  
  buttonBack(){
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  getCompraById(){
    this.compraApiService.getCompraById(this.compraId).then((response:any)=>{
      this.compra = response.data;
      this.getFamiliaById(this.compra.familia)
    });
  }
  
 getFamiliaById(familiaId){
    this.familiaApiService.getFamiliaById(familiaId).then((response:any)=>{
    this.familia = response.data;
  });
 }
}
