import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {

    constructor(){}

    getToken(){
        return localStorage.getItem('user-token') || null;
    }

    setToken(token: string){
        localStorage.setItem('user-token',token);
    }

    deleteToken(){
        localStorage.removeItem('user-token');
    }

}
