import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BonoecoApiService } from './endpoints/bonoeco.service';
import { ComercioApiService } from './endpoints/comercio.service';
import { CompraApiService} from './endpoints/compra.service';
import { StorageService } from './base/storage.service';
import { UsuarioApiService } from './endpoints/usuario.service';
import { ApiService } from './base/api.base.service';
import { FamiliaApiService } from './endpoints/familia.service';
import { AdministradorApiService } from './endpoints/administrador.service';
import { ServicioApiService } from './endpoints/servicio.service';

@NgModule({
    imports: [HttpClientModule],
    declarations: [],
    providers: [
        BonoecoApiService,
        ComercioApiService,
        CompraApiService,
        AdministradorApiService,
        UsuarioApiService,
        FamiliaApiService,
        ServicioApiService,
        StorageService,
        ApiService
    ],
    exports: [],
  })
  export class ServicesModule {}
