/* eslint-disable no-unsafe-finally */
import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { ApiService } from '../services/base/api.base.service';
import { StorageService } from '../services/base/storage.service';
import { UsuarioModel } from '../services/models/usuario.model';

@Injectable({
  providedIn: 'root'
})
export class IntroGuard implements CanLoad {

  constructor(
    private router: Router,
    private storageS: StorageService,
    private apiBase: ApiService<UsuarioModel>
  ) { }

  async canLoad(): Promise<boolean> {
    const hasToken = await this.storageS.getToken();
    let user: UsuarioModel;
    try{
      user = await this.apiBase.getSession();
    } finally{
      if (!hasToken || !user) {
        return true;
      } else {
        this.router.navigateByUrl('/home', { replaceUrl:true });
        return false;
      }
    }

}

}
