export class BonoecoModel {
    _id?: string;
    familia: string;
    nombreFamilia:string;
    bonoEcoId: string;
    cantidad: Number;
    porcentaje:Number;
    estado:string;
    // Las referencias son el id del modelo relacionado
}
