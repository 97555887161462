import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService, SearchParams } from '../base/api.base.service';
import { StorageService } from '../base/storage.service';
import { CompraModel } from '../models/compra.model';

@Injectable()
export class CompraApiService extends ApiService<CompraModel>{
    readonly model: string = 'compra';

    constructor(http: HttpClient, sessionS: StorageService){super(http, sessionS);}

    // Función que trae los compras acorde a los parametros de búsqueda
    async getCompra(params?: SearchParams){
        return await this.get(this.model, params);
    }

    // Función que trae un compra por un id
    async getCompraById(id: string){
        return await this.getbyId(this.model, id);
    }

    //Función que cuenta el numero de compras en base a parámetros
    async getNumeroCompras(params?: SearchParams){
        return await this.getCount(this.model, params);
    }

    // Función que crea un compra
    async createCompra(newEvent: CompraModel){
        return await this.postCreate(this.model, newEvent);
    }

    // Función que modifica un compra
    // Caso de uso, se quiere modificar el lugar de un compra
    async modifyCompra(id: string, newEvent: CompraModel){
        return await this.put(this.model, id, newEvent);
    }

    // Función que borra un compra
    async deleteCompra(id: string){
        return await this.delete(this.model, id);
    }


}
