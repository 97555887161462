import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService, SearchParams } from '../base/api.base.service';
import { StorageService } from '../base/storage.service';
import { ComercioModel } from '../models/comercio.modal';

@Injectable()
export class ComercioApiService extends ApiService<ComercioModel>{
    readonly model: string = 'comercio';

    constructor(http: HttpClient, sessionS: StorageService){super(http, sessionS);}

    // Función que trae los comercios acorde a los parametros de búsqueda
    async getComercio(params?: SearchParams){
        return await this.get(this.model, params);
    }

    // Función que trae un comercio por un id
    async getComercioById(id: string){
        return await this.getbyId(this.model, id);
    }

    //Función que cuenta el numero de comercios en base a parámetros
    async getNumeroComercioes(params?: SearchParams){
        return await this.getCount(this.model, params);
    }

    // Función que crea un comercio
    async createComercio(newEvent: ComercioModel){
        return await this.postCreate(this.model, newEvent);
    }

    // Función que modifica un comercio
    // Caso de uso, se quiere modificar el nombre de un comercio
    async modifyComercio(id: string, newEvent: ComercioModel){
        return await this.put(this.model, id, newEvent);
    }

    // Función que borra un comercio
    async deleteComercio(id: string){
        return await this.delete(this.model, id);
    }
}
