// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: 'https://bonoeco.guadaltech.xyz/api',
  firebaseConfig: {
    apiKey: "AIzaSyD7Ix50gTj2uixCUOBGsKl4svVnkR4zFTo",
    authDomain: "bonotest-98065.firebaseapp.com",
    databaseURL: "https://bonotest-98065-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "bonotest-98065",
    storageBucket: "bonotest-98065.appspot.com",
    messagingSenderId: "431729270820",
    appId: "1:431729270820:web:9a7364b77ac2a2c1ad78b1"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.