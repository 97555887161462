export class ComercioModel {
    _id?: string;
    usuario: string;
    servicio: string;
    movil:string;
    cif:string;
    nombre:string;
    direccion:string;
    codigoPostal:string;
    localidad:string;
    verificado:boolean
    // Las referencias son el id del modelo relacionado
}
