import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService, SearchParams } from '../base/api.base.service';
import { StorageService } from '../base/storage.service';
import { FamiliaModel } from '../models/familia.model';

@Injectable()
export class FamiliaApiService extends ApiService<FamiliaModel>{
    readonly model: string = 'familia';

    constructor(http: HttpClient, sessionS: StorageService){super(http, sessionS);}

    // Función que trae las familias acorde a los parametros de búsqueda
    async getFamilia(params?: SearchParams){
        return await this.get(this.model, params);
    }

    // Función que trae una familia por un id
    async getFamiliaById(id: string){
        return await this.getbyId(this.model, id);
    }

    //Función que cuenta el numero de asistentes en base a parámetros
    async getNumeroFamilias(params?: SearchParams){
        return await this.getCount(this.model, params);
    }

    // Función que crea una familia
    // Caso de Uso, al picar con el QR
    async createFamilia(newAsist: FamiliaModel){
        return await this.postCreate(this.model, newAsist);
    }

    // Función que modifica una familia
    // Caso de uso, al finalizar un evento se actualiza la fecha fin de pique
    async modifyFamilia(id: string, newAsist: FamiliaModel){
        return await this.put(this.model, id, newAsist);
    }

    // Función que borra una familia
    async deleteFamilia(id: string){
        return await this.delete(this.model, id);
    }


}
