export class FamiliaModel {
    _id?: string;
    usuario: string;
    nombre: string;
    movil: string;
    foto: string;
    bonoEco:string;
    nif:string
    // Las referencias son el id del modelo relacionado
}
