import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ToastController } from '@ionic/angular';
import { DH_NOT_SUITABLE_GENERATOR } from 'constants';
import { ApiService, SearchParams } from '../base/api.base.service';
import { StorageService } from '../base/storage.service';
import { UsuarioModel } from '../models/usuario.model';

@Injectable()
export class UsuarioApiService extends ApiService<UsuarioModel> {
  readonly model: string = 'usuario';

  constructor(
    http: HttpClient,
    sessionS: StorageService,
    public ngFireAuth: AngularFireAuth,
    private toastController: ToastController   ) {
    super(http, sessionS);
  }

  // Función que trae los usuarios acorde a los parametros de búsqueda
  async getUsuario(params?: SearchParams) {
    return await this.get(this.model, params);
  }

  // Función que trae un usuario por un id
  async getUsuarioById(id: string) {
    return await this.getbyId(this.model, id);
  }

  //Función que cuenta el numero de usuarios en base a parámetros
  async getNumeroUsuarios(params?: SearchParams) {
    return await this.getCount(this.model, params);
  }

  // Función que crea un usuario
  async createUsuario(newEvent: UsuarioModel) {
    return await this.postCreate(this.model, newEvent);
  }

  // Función que modifica un usuario
  // Caso de uso, se quiere modificar el nombre de un usuario
  async modifyUsuario(id: string, newEvent: UsuarioModel) {
    return await this.put(this.model, id, newEvent);
  }

  // Función que borra un usuario
  async deleteUsuario(id: string) {
    return await this.delete(this.model, id);
  }

  async loginUsuario(correo, contraseña) {
    const tk: any = await this.login(correo, contraseña);
    this.storageS.setToken(tk.token);
    return tk;
  }

  async loginUsuarioFirebase(correo) {
    const tk: any = await this.loginFirebase(correo);
    this.storageS.setToken(tk.token);
    return tk;
  }

  async signUpUsuario(newUser: UsuarioModel) {
    return await this.signUp(newUser);
  }
}
