import { Component, OnInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { FormBuilder, Validators } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { Orders, SearchParams } from "src/app/services/base/api.base.service";
import { ComercioApiService } from "src/app/services/endpoints/comercio.service";
import { ServicioApiService } from "src/app/services/endpoints/servicio.service";
import { UsuarioApiService } from "src/app/services/endpoints/usuario.service";
import { ComercioModel } from "src/app/services/models/comercio.modal";
import { UsuarioModel } from "src/app/services/models/usuario.model";

@Component({
  selector: "app-registro-comercio",
  templateUrl: "./registro-comercio.component.html",
  styleUrls: ["./registro-comercio.component.scss"],
})
export class RegistroComercioComponent implements OnInit {
  constructor(
    public modalController: ModalController,
    private comercioApiService: ComercioApiService,
    private ngFireAuth: AngularFireAuth,
    private usuarioApiService: UsuarioApiService,
    private servicioApiService: ServicioApiService,
    ) {}

    usuarioId;
    categorias;

  registroNuevoComercio = new FormBuilder().group({
    nombre: ["", Validators.required],
    movil: ["", [Validators.required, Validators.pattern("[0-9]{9}")]],
    email: ["", [Validators.required, Validators.email]],
    cif: [
      "",
      [
        Validators.required,
        Validators.pattern("([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])"),
      ],
    ],
    categoria: ["", Validators.required],
    direccion: ["", Validators.required],
    codigoPostal: [
      "",
      [
        Validators.required,
        Validators.pattern("(0[1-9][0-9]{3}|[1-4][0-9]{4}|5[0-2][0-9]{3})"),
      ],
    ],
    localidad: ["", Validators.required],
  });

  ngOnInit() {
    let order: Array<Orders> = [ {
          clave: "nombre",
          valor: 1 //desc
      },
      ];
    const params: SearchParams = {
      order
    };
    // * Buscamos los servicios por orden alfabético
    this.servicioApiService.getServicio(params).then((response:any)=>{
      console.log("categorias", response.data)
      this.categorias = response.data;
    })
  }

  buttonBack(reload = false) {
    this.modalController.dismiss({
      dismissed: true,
      reload: reload,
    });
  }

  submitNuevoComercio() {
    // * PARAMETROS PARA LA CREACION DE UN USUARIO
    const email = this.registroNuevoComercio.value.email;
    // * CREAMOS USUARIO
    const usuario = new UsuarioModel();
    usuario.correo = email;
    usuario.rol = "Comercio";
    usuario.contraseña = this.registroNuevoComercio.value.cif;

    // * PARAMETROS PARA LA CREACION DE UN COMERCIO
    const nombre = this.registroNuevoComercio.value.nombre;
    const movil = this.registroNuevoComercio.value.movil;
    const cif = this.registroNuevoComercio.value.cif;
    const categoria = this.registroNuevoComercio.value.categoria;
    const direccion = this.registroNuevoComercio.value.direccion;
    const codigoPostal = this.registroNuevoComercio.value.codigoPostal;
    const localidad = this.registroNuevoComercio.value.localidad;

    // * CREAMOS COMERCIO

    // * GUARDAMOS DATOS EN LA BASE DE DATOS 
    // ! OJO, PARA TENER UN COMERCIO PRIMERO NECESITO UN USUARIO
    this.ngFireAuth.createUserWithEmailAndPassword(usuario.correo, usuario.contraseña).then((res)=>{
      this.usuarioApiService.signUp(usuario).then(async(response:any)=>{
        const params: SearchParams = {
          domain: [
            {
              field: "correo",
              operation: "$eq",
              values: usuario.correo,
            },
          ],
        };
        await this.usuarioApiService.getUsuario(params).then((responseUsuario:any)=>{    
          console.log("AQUI EL USUARIO", responseUsuario.data[0])
          const comercio = new ComercioModel();
          comercio.usuario = responseUsuario.data[0]._id;
          comercio.nombre = nombre;
          comercio.movil = movil;
          comercio.cif = cif;
          comercio.servicio = categoria;
          comercio.direccion = direccion;
          comercio.codigoPostal = codigoPostal;
          comercio.localidad = localidad;
          // * AQUI CREAMOS EL COMERCIO
          this.comercioApiService.createComercio(comercio).then((responseComercio: any) => {
            console.log("AQUI EL COMERCIO", responseComercio.data)
            console.log("comecio creado");
            this.buttonBack(true);
          });
          console.log("respuesta", response);
        });
        
        // * TENGO EL TOKEN DEL USUARIO (response)
      })
    }).catch(async(e) => {
      console.log("ERROR EN LA AUTENTIFICACION")
      });

  }

  }
